import { registerApplication, start } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const domain = window.location.hostname;
const HOME_BY_DOMAIN = {
  localhost: "@spv/latest-home",
  "seguroparaviaje.com.pa": "@spv/latest-home",
  "seguroparaviaje.com.pe": "@spv/latest-home",
  "seguroparaviaje.com.ar": "@spv/latest-home",
  "seguroparaviaje.com.ve": "@spv/latest-home",
  "seguroparaviaje.com": "@spv/latest-home",
  "seguroparaviaje.com.hn": "@spv/latest-home",
  "seguroparaviaje.com.co": "@spv/latest-home",
  "seguroparaviaje.com.gt": "@spv/latest-home",
  "seguroparaviaje.cr": "@spv/latest-home",
  "seguroparaviaje.com.ni": "@spv/latest-home",
  "seguroparaviaje.com.do": "@spv/latest-home",
  "seguroparaviaje.com.sv": "@spv/latest-home",
  "seguroparaviaje.cl": "@spv/latest-home",
  "seguroparaviaje.com.ec": "@spv/latest-home",
  "seguroparaviaje.es": "@spv/latest-home",
  "seguroparaviaje.com.mx": "@spv/latest-home",
  "web-spv-qa.pervolare.net": "@spv/latest-home",
  "spv-qa-temp.pervolare.net": "@spv/latest-home",
  "spv-qa.pervolare.net": "@spv/latest-home",
};

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    if (name == "@spv/home") {
      name = HOME_BY_DOMAIN[domain] ? HOME_BY_DOMAIN[domain] : name;
    }

    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);
layoutEngine.activate();
start();
